$(document).ready(function() {
  $('.solution__accordion-heading').on('click', s_acc);
  $('.questions__accordion-heading').on('click', q_acc);

  $('.popup_button').on('click', function(){
    var popupNumber = $(this).attr('data-popup-button');
    console.log(popupNumber);
    $('.popup__wrapper').addClass('_active');
    $('.popup[data-popup-number="' + popupNumber + '"]').addClass('_active');
  });
  $('.popup__close, .popup__area').on('click', function(){
    $('.popup__wrapper').removeClass('_active');
    $('.popup').removeClass('_active');
  });
  
  // Мобильное меню
  $('.burger-wrapper').on('click', function(){
    $(this).toggleClass('_active');
    $('.mobile-menu').addClass('_active');
    $('body').addClass('_lock');
    $('.mobile-menu__close, .mobile-menu__link').on('click', function(){
      $('.mobile-menu').removeClass('_active');
      $('body').removeClass('_lock');
    });
  });

  // Отправка формы
  $(".form_1").submit(function() { //Change
    var th = $(this);
    $.ajax({
      type: "POST",
      url: "mail.php", //Change
      data: th.serialize()
    }).done(function() {
      setTimeout(function() {
        th.trigger("reset");
        window.location.href = "./thanks.html";
      }, 1000);
    });
    return false;
  });
  $(".form_download").submit(function() { //Change
    var th = $(this);
    $.ajax({
      type: "POST",
      url: "mail.php", //Change
      data: th.serialize()
    }).done(function() {
      setTimeout(function() {
        th.trigger("reset");
        var link = document.createElement('a');
        link.setAttribute('href', '/Файлы_АКБ-Центр.zip');
        link.setAttribute('download', 'Файлы_АКБ-Центр.zip');
        link.click();
        return false;
        window.location.href = "./thanks.html";
      }, 1000);
    });
    return false;
  });
});

function s_acc(){
  $(this).parent().siblings('.solution__accordion-item').children('.solution__accordion-body').slideUp(300);
  $(this).parent().siblings('.solution__accordion-item').children('.solution__accordion-heading').children('.solution__accordion-plus').removeClass('_active');
  $(this).siblings('.solution__accordion-body').slideToggle(300);
  $(this).children('.solution__accordion-plus').toggleClass('_active');

}
function q_acc(){
  $(this).parent().siblings('.questions__accordion-item').children('.questions__accordion-body').slideUp(300);
  $(this).parent().siblings('.questions__accordion-item').children('.questions__accordion-heading').children('.questions__accordion-plus').removeClass('_active');
  $(this).siblings('.questions__accordion-body').slideToggle(300);
  $(this).children('.questions__accordion-plus').toggleClass('_active');
}


// Swiper slider
var swiper = new Swiper('.reviews__slider', {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  navigation: {
    nextEl: '.reviews__slider-next',
    prevEl: '.reviews__slider-prev',
  },
  breakpoints: {
    992: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    }
  }
});
var swiper = new Swiper('.pictures__slider', {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  navigation: {
    nextEl: '.pictures__slider-next',
    prevEl: '.pictures__slider-prev',
  },
  breakpoints: {
    992: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    }
  }
});
var swiper = new Swiper('.steps__slider', {
  slidesPerView: 1,
  spaceBetween: 14,
  loop: true,
  navigation: {
    nextEl: '.steps__slider-next',
    prevEl: '.steps__slider-prev',
  },
  breakpoints: {
    992: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 15,
    }
  }
});